import * as React from 'react'
import loadable from '@loadable/component'

import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

const IconGift = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/AccountIcons/AccountGift'
    ),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export const Bar = styled.div<{ completedRatio: number }>`
  height: 100%;
  width: ${(props) => props.completedRatio}%;
  background-color: ${(props) => props.theme.colors.success.base};
`

export const Progress = styled.div`
  width: 100%;
  height: 8px;
  margin: auto 0;
  background-color: ${(props) => props.theme.colors.palette.greys.grey};
`

export const ProgressBarContainer = styled.div`
  display: flex;
  gap: ${spacing(1)};
  margin-bottom: ${spacing(1)};
`

export const StyledIconGift = styled(IconGift)`
  width: 16px;
  height: 16px;

  path {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const IconGiftContainer = styled.span`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

export const MetaContainer = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'flex')};
  justify-content: space-between;
`

export const UnqualifiedTotalWrapper = styled.p`
  margin-bottom: ${spacing(1)};
`

export const Meta = styled.span<{ bold?: boolean }>`
  ${(props) => Text('bodyText', props.bold ? 'alternate' : 'default')}
`
