import * as React from 'react'
import loadable from '@loadable/component'

import { Button, PlatformMessage } from '@thg-commerce/gravity-elements'
import { ButtonProps } from '@thg-commerce/gravity-elements/Button/Button'
import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

import { useTheme } from '../../theme'

const IconSuccess = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconSuccess'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const IconInfo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconInfo'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export const StyledIconSuccess = styled(IconSuccess)`
  margin-right: ${spacing(2)};
  path {
    fill: ${(props) => props.theme.colors.success.base};
  }
`

export const QualifiedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(2)};
`

export const StyledPlatformMessage = styled(PlatformMessage)`
  margin-bottom: ${spacing(2)};
`

export const QualifiedText = styled.p`
  ${Text('bodyText', 'alternate')}
  color: ${(props) => props.theme.colors.success.base};
`

export const SelectedGifts = styled.p<{ bold?: boolean }>`
  margin-bottom: ${spacing(2)};
  ${(props) => Text('bodyText', props.bold ? 'alternate' : 'default')}
`

export const TierContainer = styled.div`
  margin-top: ${spacing(3)};

  &:not(:last-child) {
    margin-bottom: ${spacing(4)};
  }
`

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1)};
`

export const Product = styled.div<{
  isSelected: boolean
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  padding: ${spacing(1)};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  box-shadow: ${(props) =>
    props.isSelected
      ? `0px 0px 0px 2px ${props.theme.colors.palette.brand.base} inset`
      : 'none'};

  ${(props) =>
    props.disabled
      ? `
        opacity: 0.5; 
        cursor: default;
      `
      : 'cursor: pointer;'}
`

export const ProductTitle = styled.p`
  margin: auto 0 auto ${spacing(2)};
  flex: 1;
  text-align: left;
`

export const InfoIconButton = styled(Button)`
  background: none;
  align-self: center;
  margin-left: ${spacing(1)};
  width: 32px;
`

export const InfoIcon = (props: ButtonProps) => {
  const theme = useTheme()
  return (
    <InfoIconButton emphasis="low" {...props}>
      <IconInfo
        fill={
          theme.pageTheme.selectYourSample.productDescription.accordion
            .chevronIcon?.fillColor
        }
      />
    </InfoIconButton>
  )
}
