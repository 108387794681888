import { spacing, styled } from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.lighter};
`

export const ClosedContainer = styled.div<{
  isOpen: boolean
  qualifiedWhenClosed: boolean
}>`
  padding: ${spacing(1)} ${spacing(2)};

  display: ${(props) =>
    props.isOpen ? 'none' : props.qualifiedWhenClosed ? 'flex' : 'block'};
  justify-content: ${(props) =>
    props.qualifiedWhenClosed ? 'space-between' : 'flex-start'};
`

export const ContentContainer = styled.div`
  padding: 0 ${spacing(2)} ${spacing(2)};
`
