import * as React from 'react'
import dynamic from 'next/dynamic'

import { i18n, useSiteConfig } from '@thg-commerce/enterprise-core'
import { withTheme } from '@thg-commerce/enterprise-theme'
import {
  getLiveChatContainer,
  isLiveChatAvailable,
} from '@thg-commerce/enterprise-utils/src/LiveChat/LiveChat'
import { TextStyle } from '@thg-commerce/gravity-theme'

const GravityCircle = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/GravityCircle'),
  { loading: () => <div style={{ width: 24, height: 24 }} /> },
)

import {
  ActiveSearchText,
  BottomContainer,
  ChatIcon,
  ChatTextContainer,
  Container,
  IconContainer,
  LiveChatWrapper,
  OnlineText,
  OnlineTextContainer,
  OperatorsText,
  StyledLiveChatButton,
  SubtitleLine,
  TopContainer,
} from './styles'

const liveChatAvailabilityCheckInterval = 2000

interface ButtonStateStyleInterface {
  button: {
    backgroundColor: string
    border: {
      width: string
      color: string
    }
    text: {
      style: TextStyle
      color: string
      textDecoration: string
    }
    boxShadow: {
      shadowX: string
      shadowY: string
      shadowBlur: string
      shadowSpread: string
      shadowColor: string
    }
  }
}
export interface StartLiveChatCTABasketStyleInterface {
  default: ButtonStateStyleInterface
  hover: ButtonStateStyleInterface
  focus: ButtonStateStyleInterface
  active: ButtonStateStyleInterface
}

export const LiveChat = withTheme((props) => {
  const { liveChatEnabled } = useSiteConfig()
  if (!liveChatEnabled) {
    return null
  }

  const i18nText = {
    liveChat: i18n('component.livechat.title.text'),
    operators: i18n('component.livechat.operators.text'),
    online: i18n('component.livechat.online.text'),
    connectionTime: i18n('component.livechat.info.text'),
    startChat: i18n('component.livechat.chat.button.text'),
  }

  const [isActive, setIsActive] = React.useState<boolean>(false)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsActive(isLiveChatAvailable(document))
    }, liveChatAvailabilityCheckInterval)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const triggerLivePerson = () => {
    isActive &&
      getLiveChatContainer(document) &&
      getLiveChatContainer(document)?.click()
  }

  return (
    <React.Fragment>
      {isActive && (
        <Container>
          <TopContainer>
            <ChatTextContainer>
              <IconContainer
                iconContainerStyleOverride={props.iconContainerStyleOverride}
              >
                {props.theme.site.favicon ? (
                  <ChatIcon
                    chatIconStyleOverride={props.chatIconStyleOverride}
                  />
                ) : (
                  <GravityCircle />
                )}
              </IconContainer>
              <div>
                <SubtitleLine liveChatTextEntry={props.liveChatTextEntry}>
                  {i18nText.liveChat}
                </SubtitleLine>
                <OnlineTextContainer>
                  <OperatorsText>{i18nText.operators}</OperatorsText>
                  <OnlineText onlineTextStyle={props.onlineTextStyle}>
                    {i18nText.online}
                  </OnlineText>
                </OnlineTextContainer>
              </div>
            </ChatTextContainer>
          </TopContainer>
          <BottomContainer
            containerStyleOverride={props.containerStyleOverride}
          >
            <ActiveSearchText>{i18nText.connectionTime}</ActiveSearchText>
            <StyledLiveChatButton
              startChatCTAStyle={props.startChatCTAStyle}
              onClick={triggerLivePerson}
            >
              {i18nText.startChat}
            </StyledLiveChatButton>
          </BottomContainer>
        </Container>
      )}
      <LiveChatWrapper>
        {/* Don't change these class names, GTM uses them to add the LivePerson scripts in */}
        <div className="lp-panel">
          <div id="checkout-lpButtonDiv" />
        </div>
      </LiveChatWrapper>
    </React.Fragment>
  )
})
