import * as React from 'react'

import { SafeHtml } from '@thg-commerce/gravity-elements'
import { margin, mq, padding, spacing, Text } from '@thg-commerce/gravity-theme'

import { styled } from '../theme'

import { SAFEHTML_ALLOWED_ATTRIBUTES, SAFEHTML_ALLOWED_TAGS } from './config'
import { ProductDescriptionAccordionProps } from './types'

const Container = styled.div`
  flex-basis: 100%;
  flex-direction: column;

  ${(props) =>
    padding(
      props.theme.patterns.productDescriptionAccordion.container.padding,
    )};
`

const Item = styled.div`
  display: ${(props) =>
    props.theme.patterns.productDescriptionAccordion.details.item.display};
  flex-direction: ${(props) =>
    props.theme.patterns.productDescriptionAccordion.details.item.direction};

  ${({ theme }) => mq(theme.breakpointUtils.map, 'sm')} {
    flex-direction: row;
  }

  &:nth-child(odd) {
    background-color: ${(props) =>
      props.theme.patterns.productDescriptionAccordion.details.backgroundColor
        ? props.theme.patterns.productDescriptionAccordion.details
            .backgroundColor
        : props.theme.colors.palette.greys.lighter};
  }
`

const StyledUl = styled.ul`
  margin-left: ${spacing(2.5)};
  list-style-type: disc;
`

const Title = styled.p`
  ${Text('bodyText', 'alternate')}
  ${(props) =>
    padding(
      props.theme.patterns.productDescriptionAccordion.details.title.padding,
    )}

  flex-basis: 25%;
  min-width: 50%;
`

const StyledSafeHTML = styled(SafeHtml)`
  ${(props) =>
    padding(
      props.theme.patterns.productDescriptionAccordion.details.item.padding,
    )}

  display: flex;
  align-items: center;
  flex-basis: 75%;
  flex-grow: 1;

  p {
    ${Text('bodyText', 'default')};
    ${(props) =>
      props.theme.patterns.productDescriptionAccordion.details.item.margin &&
      margin(
        props.theme.patterns.productDescriptionAccordion.details.item.margin,
      )}
  }

  strong {
    ${Text('bodyText', 'alternate')};
  }

  h2 {
    ${Text('large2', 'alternate')};
  }

  h3 {
    ${Text('large1', 'alternate')};
  }
`

export const Details: React.FunctionComponent<{
  details: Required<ProductDescriptionAccordionProps['details']>
}> = (props) => {
  if (!props.details) {
    return null
  }

  return (
    <Container>
      {props.details.items && props.details?.displayDetailsAsList ? (
        <StyledUl>
          {props.details.items.map((item) => {
            return (
              <li>
                <Item key={item.key}>
                  <StyledSafeHTML
                    removeTagsAndContent={false}
                    __dangerouslyAllowedTags={SAFEHTML_ALLOWED_TAGS}
                    __dangerouslyAllowedAttrs={SAFEHTML_ALLOWED_ATTRIBUTES}
                    content={item.value}
                  />
                </Item>
              </li>
            )
          })}
        </StyledUl>
      ) : (
        <React.Fragment>
          {props.details.items.map((item) => {
            return (
              <Item key={item.key}>
                <Title>{item.title}</Title>
                <StyledSafeHTML
                  removeTagsAndContent={false}
                  __dangerouslyAllowedTags={SAFEHTML_ALLOWED_TAGS}
                  __dangerouslyAllowedAttrs={SAFEHTML_ALLOWED_ATTRIBUTES}
                  content={item.value}
                />
              </Item>
            )
          })}
        </React.Fragment>
      )}
    </Container>
  )
}
