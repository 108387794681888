import * as React from 'react'

import {
  TotalRrpWrapper,
  TotalSavingLabel,
  TotalSavingValue,
} from '../../styles'

interface DiscountedPrice {
  totalSaving?: string
  displayDiscountFromRrp?: string
}

export const DiscountedPrice = (props: DiscountedPrice) => {
  return (
    <TotalRrpWrapper data-testid="total-saving-wrapper">
      <TotalSavingLabel>{props.totalSaving}</TotalSavingLabel>
      <TotalSavingValue>{props.displayDiscountFromRrp}</TotalSavingValue>
    </TotalRrpWrapper>
  )
}
