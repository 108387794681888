import * as React from 'react'

import { LiveChat } from '@thg-commerce/enterprise-components'
import { Routes, useSiteConfig } from '@thg-commerce/enterprise-core'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { BasketMessageType } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  BasketItemSeparatorMargin,
  useTheme,
} from '@thg-commerce/enterprise-pages/src/Basket/theme'
import { PlatformMessage } from '@thg-commerce/gravity-elements'

import { BasketRecommendationsRenderer } from '../../../BasketRecommendationsRenderer'
import { CheckoutErrorType } from '../../../CheckoutError'
import { ClickAndCollectInfoModalButton } from '../../../ClickAndCollect'
import { ConfirmationModalPresenter } from '../../../ConfirmationModalPresenter'
import { Discount } from '../../../Discount'
import { Product, SelectYourSampleContext } from '../../../SelectYourSample'
import { Separator } from '../../../Table/styles'
import {
  BottomButtonsWrapper,
  Checkout,
  CheckoutContainer,
  ContinueShopping,
  DeliveryMessage,
  DetailsContainer,
  HeadingCheckout,
  LiveChatWrapper,
  LockIcon,
  PageGridItem,
  PageHeadingContainer,
  SelectYourSampleGridItem,
  SelectYourSampleWrapper,
  SeparatorWrapper,
  StandardPrice,
  StandardPriceLabel,
  StandardPriceValue,
  StyledCheckoutError,
  StyledLoyaltyPoints,
  StyledMessages,
  StyledMessagesAlternate,
  StyledOfferSummary,
  StyledPaymentIcons,
  StyledReferrals,
  StyledSelectYourSample,
  StyledSummaryTitle,
  StyledTable,
  SubTitle,
  Subtotal,
  SubtotalLabel,
  SubtotalValue,
  SubtotalWrapper,
  TableWrapper,
  Title,
  TotalRrpLabel,
  TotalRrpValue,
  TotalRrpWrapper,
} from '../../styles'

import { DiscountedPrice } from './DiscountedPrice'

const DropshipMessage = (props: { dropshipMessage?: string }) => {
  return (
    <StyledMessagesAlternate>
      <PlatformMessage
        type="info"
        text={props.dropshipMessage || ''}
        data-testid="dropship-message"
      />
    </StyledMessagesAlternate>
  )
}

interface BasketWithItemsProps {
  shouldDisplayClickAndCollect?: boolean
  shouldSelectYourSampleBeOpen: (
    tiers: BasketData['selectYourSample'][0]['tiers'],
  ) => boolean
  basket: BasketData
  selectYourSampleModalOpen: boolean
  setSelectYourSampleModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectYourSampleInteracted: boolean
  setSelectYourSampleInteracted: React.Dispatch<React.SetStateAction<boolean>>
  onCheckoutClick?: (event: React.MouseEvent) => void
  checkoutStartError?: CheckoutErrorType | undefined
  hideProductRecommendations: boolean
  i18nText: {
    metaTagTitle: string
    siteTitleEnd: string
    pageTitle: string
    checkoutStart: string
    recommendationsTitle: string
    basketSubtotal: string
    basketTotal: string
    item: string
    items: string
    continueShopping: string
    loyaltyText: string
    deliveryChargeMessage: string
    rrpText?: string
    orderSummaryTitle?: string
    standardPriceTitle?: string
    totalSaving?: string
    discountSaving?: string
    totalRrp?: string
    dropshipMessage?: string
  }
  showDeliveryCalculatedAtCheckoutMessage?: boolean
  basketShowTotalRrpDiscountPerItem?: boolean
  basketItemSeparatorMargin?: BasketItemSeparatorMargin
  showDropshipMessage?: boolean
}

const disabledCheckoutButton = (basket) => {
  return basket.some(
    (message) => message.type === BasketMessageType.ProductOutOfStock,
  )
}

const subtitle = (basket, basketTotal, item, items) => {
  return `${basketTotal} ${basket.chargePrice.displayValue} (${
    basket.totalQuantity
  } ${basket.totalQuantity === 1 ? item : items})`
}

const renderStackedLayout = (selectYourSample, themeLayout) => {
  return selectYourSample.length ? 'stacked' : themeLayout
}

const hasDiscounts = (
  basket: BasketData,
  basketShowTotalRrpDiscountPerItem?: boolean,
  enableDiscountPrice?: boolean,
) => {
  const hasAppliedOffers = (basket.appliedOffers || []).length > 0
  const hasDiscountPerItem =
    basketShowTotalRrpDiscountPerItem && parseFloat(basket.discount?.amount) > 0
  const hasDiscountFromRrp =
    enableDiscountPrice &&
    parseFloat(basket.discountFromRrpExcludingOffers?.amount || '0') > 0

  return hasAppliedOffers || hasDiscountPerItem || hasDiscountFromRrp
}

export const BasketWithItems = ({
  shouldDisplayClickAndCollect,
  showDeliveryCalculatedAtCheckoutMessage,
  basketShowTotalRrpDiscountPerItem,
  shouldSelectYourSampleBeOpen,
  i18nText,
  basket,
  onCheckoutClick,
  checkoutStartError,
  hideProductRecommendations,
  selectYourSampleModalOpen,
  setSelectYourSampleModalOpen,
  selectYourSampleInteracted,
  setSelectYourSampleInteracted,
  showDropshipMessage,
}: BasketWithItemsProps) => {
  const theme = useTheme()
  const selectYourSampleInformationModalPresenter = React.useRef<
    (product: Product) => void | undefined
  >()

  const enableDiscountPrice =
    theme.pageTheme.basketWithItems.enableDiscountedPrice

  const {
    reorderInfoMessage,
    displayBasketWithStandardPrice,
    displayBasketWithOrderSummaryTitle,
  } = useSiteConfig()

  const gridColSpan = [12, 12, 12, basket.selectYourSample.length > 0 ? 8 : 12]

  const showDiscount = hasDiscounts(
    basket,
    basketShowTotalRrpDiscountPerItem,
    enableDiscountPrice,
  )

  const selectYourSample = React.useMemo(
    () =>
      basket.selectYourSample.map((sample) => (
        <StyledSelectYourSample
          {...sample}
          key={sample.title}
          isOpen={shouldSelectYourSampleBeOpen(sample.tiers)}
          selectYourSampleTheme={theme?.pageTheme?.selectYourSample}
        />
      )),
    [
      basket.selectYourSample,
      shouldSelectYourSampleBeOpen,
      theme?.pageTheme?.selectYourSample,
    ],
  )

  return (
    <React.Fragment>
      <SelectYourSampleContext.Provider
        value={{
          selectYourSampleInteracted,
          setSelectYourSampleInteracted,
          informationModalPresenter: selectYourSampleInformationModalPresenter,
        }}
      >
        <PageGridItem
          colSpan={gridColSpan}
          colStart={[0, 0, 0, 0]}
          margin={theme.pageTheme.basketWithItems.pageGridWithItems?.margin}
        >
          <PageHeadingContainer>
            <div>
              <Title>{i18nText.pageTitle}</Title>
              <SubTitle>
                {subtitle(
                  basket,
                  i18nText.basketTotal,
                  i18nText.item,
                  i18nText.items,
                )}
              </SubTitle>
            </div>
            <HeadingCheckout
              disabled={disabledCheckoutButton(basket.messages)}
              onClick={onCheckoutClick}
              aria-label={i18nText.checkoutStart}
              data-testid="checkout-start"
              icon={LockIcon}
              iconAlignment="center"
            >
              <span>{i18nText.checkoutStart}</span>
            </HeadingCheckout>
          </PageHeadingContainer>
          {checkoutStartError && (
            <StyledCheckoutError error={checkoutStartError} />
          )}
          <StyledReferrals />
          {shouldDisplayClickAndCollect && <ClickAndCollectInfoModalButton />}
          {basket.messages.length > 0 && (
            <StyledMessages
              messages={
                reorderInfoMessage
                  ? basket.messages.sort((a, b) => {
                      return (
                        (b.type === BasketMessageType.Upsell ? 1 : 0) -
                        (a.type === BasketMessageType.Upsell ? 1 : 0)
                      )
                    })
                  : basket.messages
              }
              lastMessage={!!showDropshipMessage}
            />
          )}
          {showDropshipMessage && (
            <DropshipMessage dropshipMessage={i18nText.dropshipMessage} />
          )}
          <TableWrapper
            layout={renderStackedLayout(
              basket.selectYourSample,
              theme.pageTheme.table?.layout,
            )}
          >
            <div>
              <StyledTable
                basket={basket}
                basketItemSeparatorMargin={
                  theme.pageTheme.basketItemSeparatorMargin
                }
              />
              <SelectYourSampleWrapper>
                {selectYourSample}
              </SelectYourSampleWrapper>
            </div>
            <DetailsContainer
              layout={renderStackedLayout(
                basket.selectYourSample,
                theme.pageTheme.table?.layout,
              )}
            >
              <Discount />
              {displayBasketWithOrderSummaryTitle && (
                <React.Fragment>
                  <SeparatorWrapper>
                    <Separator topMargin={0} bottomMargin={0} />
                  </SeparatorWrapper>
                  <StyledSummaryTitle>
                    {i18nText.orderSummaryTitle}
                  </StyledSummaryTitle>
                </React.Fragment>
              )}
              {showDiscount && (
                <React.Fragment>
                  {displayBasketWithStandardPrice && (
                    <StandardPrice>
                      <StandardPriceLabel>
                        {i18nText.standardPriceTitle}
                      </StandardPriceLabel>
                      <StandardPriceValue>
                        {basket.standardPrice?.displayValue}
                      </StandardPriceValue>
                    </StandardPrice>
                  )}
                  <SeparatorWrapper>
                    <Separator topMargin={0} bottomMargin={0} />
                  </SeparatorWrapper>
                  {enableDiscountPrice && (
                    <TotalRrpWrapper>
                      <TotalRrpLabel>{i18nText.totalRrp}:</TotalRrpLabel>
                      <TotalRrpValue>
                        {basket.totalRrp?.displayValue}
                      </TotalRrpValue>
                    </TotalRrpWrapper>
                  )}
                  <StyledOfferSummary
                    basketShowTotalRrpDiscountPerItem={
                      basketShowTotalRrpDiscountPerItem
                    }
                    enableDiscountPrice={enableDiscountPrice}
                    appliedOffers={basket.appliedOffers || []}
                    discount={basket.discount}
                    discountFromRrpExcludingOffers={
                      basket.discountFromRrpExcludingOffers
                    }
                  />
                  {enableDiscountPrice && (
                    <DiscountedPrice
                      totalSaving={i18nText.totalSaving}
                      displayDiscountFromRrp={
                        basket.discountFromRrp?.displayValue
                      }
                    />
                  )}
                </React.Fragment>
              )}
              {basket.earnableLoyaltyPoints && (
                <React.Fragment>
                  <SeparatorWrapper>
                    <Separator topMargin={0} bottomMargin={0} />
                  </SeparatorWrapper>
                  <StyledLoyaltyPoints pointsText={i18nText.loyaltyText} />
                </React.Fragment>
              )}
              <SeparatorWrapper>
                <Separator topMargin={0} bottomMargin={0} />
              </SeparatorWrapper>
              <SubtotalWrapper>
                {showDeliveryCalculatedAtCheckoutMessage && (
                  <DeliveryMessage data-testid="deliveryChargeMessage">
                    {i18nText.deliveryChargeMessage}
                  </DeliveryMessage>
                )}

                <Subtotal data-testid="basket-subtotal">
                  <SubtotalLabel>{i18nText.basketSubtotal}</SubtotalLabel>
                  <SubtotalValue
                    data-testid="basket-subtotal-value"
                    textFont={
                      theme.pageTheme.basketWithItems.subtotalValueFont?.entry
                    }
                  >
                    {basket.chargePrice.displayValue}
                  </SubtotalValue>
                </Subtotal>
              </SubtotalWrapper>
              <BottomButtonsWrapper>
                <CheckoutContainer>
                  <Checkout
                    disabled={disabledCheckoutButton(basket.messages)}
                    onClick={onCheckoutClick}
                    aria-label={i18nText.checkoutStart}
                    data-testid="checkout-start"
                    icon={LockIcon}
                    iconAlignment="center"
                  >
                    {i18nText.checkoutStart}
                  </Checkout>
                </CheckoutContainer>
                <StyledPaymentIcons
                  paymentMethods={basket.availablePaymentOptions || []}
                  order={theme?.pageTheme?.basketWithItems?.paymentIcons?.order}
                  marginBottom={
                    theme?.pageTheme?.basketWithItems?.paymentIcons?.margin
                      ?.bottom
                  }
                />
                <ContinueShopping
                  emphasis="medium"
                  renderedAs="a"
                  href={Routes.HomePage}
                  aria-label={i18nText.continueShopping}
                  order={
                    theme?.pageTheme?.basketWithItems?.continueShopping?.order
                  }
                  marginBottom={
                    theme?.pageTheme?.basketWithItems?.continueShopping?.margin
                      ?.bottom
                  }
                >
                  <span>{i18nText.continueShopping}</span>
                </ContinueShopping>
              </BottomButtonsWrapper>
              <LiveChatWrapper>
                <LiveChat
                  iconContainerStyleOverride={theme.pageTheme.table.icon}
                  chatIconStyleOverride={
                    theme.pageTheme.table.liveChat?.chatIcon
                  }
                  liveChatTextEntry={theme.pageTheme.table.liveChat?.textEntry}
                  onlineTextStyle={theme.pageTheme.table.liveChat?.textStyle}
                  containerStyleOverride={
                    theme.pageTheme.table.liveChat?.container
                  }
                  startChatCTAStyle={
                    theme.pageTheme.table.liveChat?.startChatCTA
                  }
                />
              </LiveChatWrapper>
            </DetailsContainer>
          </TableWrapper>
        </PageGridItem>
        {basket.selectYourSample.length > 0 && (
          <React.Fragment>
            <SelectYourSampleGridItem
              colSpan={[0, 0, 0, 4]}
              colStart={[9, 9, 9, 9]}
            >
              {selectYourSample}
            </SelectYourSampleGridItem>
            <ConfirmationModalPresenter
              open={selectYourSampleModalOpen}
              setOpen={setSelectYourSampleModalOpen}
              basket={basket}
            />
          </React.Fragment>
        )}
      </SelectYourSampleContext.Provider>
      {!hideProductRecommendations && (
        <BasketRecommendationsRenderer
          basket={basket}
          i18nText={{
            recommendationsTitle: i18nText.recommendationsTitle,
            rrpText: i18nText.rrpText,
          }}
        />
      )}
    </React.Fragment>
  )
}
