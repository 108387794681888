import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  EnterpriseContext,
  i18n,
  useBasketId,
  useFormattableI18nProperty,
  useHorizonSessionSettings,
  useSiteConfig,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { useBasket } from '@thg-commerce/enterprise-core/src/Basket/hooks/useBasketId'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { pushToEventGA4 } from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import {
  ApplyCodeToBasketData,
  ApplyCodeToBasketVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/ApplyCodeToBasket'

import { ApplyCodeToBasket as ADD_DISCOUNT_MUTATION } from '../../../graphql/Mutation/ApplyCodeToBasket.graphql'

import {
  Container,
  DiscountButton,
  DiscountInputContainer,
  InputWrapper,
  Label,
  LabelContainer,
  StyledInput,
  StyledPlatformMessage,
} from './styles'

const ErrorMessage = (props: {
  invalid: boolean
  referralInvalid: boolean
  referrerNotEligible: boolean
  referralNotLoggedIn: boolean
  expired: boolean
  notApplicable: boolean
  betterOffer: boolean
  i18nText: {
    discountExpiredMessage: string
    discountInvalidMessage: string
    referralNotLoggedInMessage: string
    referralInvalidMessage: string
    referrerNotEligible: string
    discountBetterOfferMessage: string
  }
}) => {
  if (props.referralInvalid) {
    return (
      <StyledPlatformMessage
        type="error"
        text={props.i18nText.referralInvalidMessage}
        data-testid="referral-invalid-message"
      />
    )
  }
  if (props.referralNotLoggedIn) {
    return (
      <StyledPlatformMessage
        type="success"
        text={props.i18nText.referralNotLoggedInMessage}
        data-testid="referral-notloggedin-message"
      />
    )
  }
  if (props.referrerNotEligible) {
    return (
      <StyledPlatformMessage
        type="error"
        text={props.i18nText.referrerNotEligible}
        data-testid="referral-not-eligible-message"
      />
    )
  }
  if (props.invalid || props.notApplicable) {
    return (
      <StyledPlatformMessage
        type="error"
        text={props.i18nText.discountInvalidMessage}
        data-testid="discount-invalid-message"
      />
    )
  }
  if (props.expired) {
    return (
      <StyledPlatformMessage
        type="error"
        text={props.i18nText.discountExpiredMessage}
        data-testid="discount-expired-message"
      />
    )
  }
  if (props.betterOffer) {
    return (
      <StyledPlatformMessage
        type="error"
        text={props.i18nText.discountBetterOfferMessage}
      />
    )
  }
  return null
}

export const Discount = () => {
  const [basketId, setBasketId] = useBasketId()
  const { basket } = useBasket()
  const { useGA4EnhancedEcom } = useSiteConfig()
  const sessionSettings = useHorizonSessionSettings()
  const transmit = useTransmit()
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { extensionsRef } = React.useContext(EnterpriseContext)
  const {
    value: [getExtensions],
  } = extensionsRef

  const discountInput = React.useRef<HTMLInputElement>(null)
  const [discountValue, setDiscountValue] = React.useState('')

  const i18nText = {
    discountCode: i18n('basket.discount.code.text'),
    discountLabel: i18n('basket.discount.label.text'),
    applyCode: i18n('basket.discount.apply.text'),
    discountExpiredMessage: i18n('basket.discountapply.expired.text'),
    discountInvalidMessage: i18n('basket.discountapply.invalid.text'),
    referralNotLoggedInMessage: i18n('basket.referrals.unauthenticated'),
    referralSuccessful: i18n('basket.referrals.code.applied'),
    referralInvalidMessage: i18n('basket.referrals.code.invalid'),
    referrerNotEligible: i18n('basket.referrals.code.referrernoteligible'),
    discountBetterOfferMessage: i18n(
      'basket.discountapply.betterofferapplied.text',
    ),
    appliedMessage: useFormattableI18nProperty(
      'basket.discountapply.applied.text',
    ),
  }

  const [addDiscount, { data }] = useMutation<
    { applyCodeToBasket: ApplyCodeToBasketData },
    ApplyCodeToBasketVariables
  >(ADD_DISCOUNT_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const extensions = getExtensions()

      if (data?.applyCodeToBasket?.basket) {
        setBasketId(data.applyCodeToBasket.basket.id)
        setDiscountValue('')
      }

      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: data.applyCodeToBasket.discountMessage ?? '',
          eventCategory: 'Basket Message',
          eventLabel: data.applyCodeToBasket.discountCode,
        },
      })

      transmit({
        type: 'discount_code_event',
        payload: normaliseBackendEvent({
          basket,
          eventData: {
            type: 'discount_code_event',
          },
          rays: [extensions?.ray || ''],
          experiments: extensions?.experiments,
          requestData: {
            ...(extensions?.LoggerLinkData || {
              start_timestamp: Date.now(),
              duration_ms: 0,
            }),
            url: window.location.href,
          },
        }),
      })

      if (data.applyCodeToBasket.successful) {
        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Valid',
            eventCategory: 'Discount codes',
            eventLabel: data.applyCodeToBasket.discountCode,
          },
        })
      }

      if (data.applyCodeToBasket.expired) {
        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Inactive',
            eventCategory: 'Discount codes',
            eventLabel: data.applyCodeToBasket.discountCode,
          },
        })
      }

      if (data.applyCodeToBasket.invalid) {
        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Not Valid',
            eventCategory: 'Discount codes',
            eventLabel: data.applyCodeToBasket.discountCode,
          },
        })
      }

      if (data.applyCodeToBasket.betterOffer) {
        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Better Offer Applied',
            eventCategory: 'Discount codes',
            eventLabel: data.applyCodeToBasket.discountCode,
          },
        })
      }

      if (data.applyCodeToBasket.notApplicable) {
        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Not Applicable to Basket Content',
            eventCategory: 'Discount codes',
            eventLabel: data.applyCodeToBasket.discountCode,
          },
        })
      }

      const failedToApplyDiscountCode =
        data.applyCodeToBasket?.invalid ||
        data.applyCodeToBasket?.referrerNotEligible ||
        data.applyCodeToBasket?.referralNeedsRegistration ||
        data.applyCodeToBasket?.expired ||
        data.applyCodeToBasket?.notApplicable ||
        data.applyCodeToBasket?.betterOffer

      if (useGA4EnhancedEcom) {
        return failedToApplyDiscountCode
          ? pushToEventGA4({
              event: 'custom_event',
              event_name: 'apply_coupon_fail',
              coupon_code: data.applyCodeToBasket.discountCode,
            })
          : pushToEventGA4({
              event: 'custom_event',
              event_name: 'apply_coupon_success',
              coupon_code: data.applyCodeToBasket.discountCode,
            })
      }
    },
  })

  const handleAddDiscount = () => {
    discountInput.current?.value &&
      addDiscount({
        variables: {
          basketId,
          sessionSettings,
          code: discountInput.current.value,
        },
      })
  }

  return (
    <Container>
      <LabelContainer>
        <Label>{i18nText.discountCode}</Label>
      </LabelContainer>
      <InputWrapper>
        <DiscountInputContainer>
          <StyledInput
            label={i18nText.discountCode}
            ariaLabel={i18nText.discountCode}
            labelHidden={true}
            bindref={discountInput}
            data-testid="basket-discount-input"
            valueOverride={discountValue}
            onChange={(event) => {
              setDiscountValue(event.target.value)
            }}
            onKeyPress={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                handleAddDiscount()
              }
            }}
            clearInputValueHandler={() => {
              setDiscountValue('')
            }}
          />
        </DiscountInputContainer>
        <DiscountButton
          data-testid="basket-discount-add-button"
          onClick={handleAddDiscount}
          emphasis="medium"
        >
          {i18nText.applyCode}
        </DiscountButton>
      </InputWrapper>
      {data?.applyCodeToBasket?.basket?.id === basketId && (
        <ErrorMessage
          invalid={data?.applyCodeToBasket?.invalid || false}
          referralInvalid={
            (data?.applyCodeToBasket?.invalid &&
              data?.applyCodeToBasket.isReferralCode) ||
            false
          }
          referrerNotEligible={
            data?.applyCodeToBasket?.referrerNotEligible || false
          }
          referralNotLoggedIn={
            data?.applyCodeToBasket?.referralNeedsRegistration || false
          }
          expired={data?.applyCodeToBasket?.expired || false}
          notApplicable={data?.applyCodeToBasket?.notApplicable || false}
          betterOffer={data?.applyCodeToBasket?.betterOffer || false}
          i18nText={i18nText}
        />
      )}
      {data?.applyCodeToBasket?.basket?.id === basketId &&
        data?.applyCodeToBasket?.successful &&
        !data?.applyCodeToBasket?.isReferralCode && (
          <StyledPlatformMessage
            type="success"
            text={i18nText.appliedMessage(
              data.applyCodeToBasket.discountMessage || '',
            )}
            data-testid="discount-success-message"
          />
        )}
      {data?.applyCodeToBasket?.referralSuccessful && (
        <StyledPlatformMessage
          type="success"
          text={i18nText.referralSuccessful}
          data-testid="referral-success-message"
        />
      )}
    </Container>
  )
}
